<template>
    <div class="edu-aids">
        <div style="position:relative">
        <img class="bannerImg" src="../assets/images/edu_products/aidsBanner.png" alt="">
        <div class="banner">
            <p class="title">人工智能教具</p>
            <p>国产人工智能教育科研解决方案</p>
            <p>多种传感器协调工作、将AI算法与机械控制相结合的人工智能教学器具,全面提升学生在人工智能、电子、机械方面的综合能力。</p>
        </div>
        </div>
        <div class="paragraphs">
            <div class="productTab">
                <div class="tabs">
                    <span :class="isActive==1? 'active' : ''" @click="isActive = 1">智能象棋对弈机器人</span>
                    <span :class="isActive==2? 'active' : ''" @click="isActive = 2" >全自动锁螺丝设备</span>
                     <span :class="isActive==3? 'active' : ''" @click="isActive =3" >CCD光学检测设备</span>
                </div>
                <div class="productChildren" v-if="isActive==1">
                    <div class="introduction">{{robot.introduction}}</div>
                    <div class="device">
                        <div class="left" :style="{ 'backgroundImage': `url(${robot.device.url})` ,'background-repeat':'no-repeat','background-position':'center','background-size':'cover','height':'4rem'}"></div>
                        <div class="right">
                            <div class="subtitle">{{robot.device.title}}</div>
                            <p class="content" v-for="(item,i) of robot.device.contents" :key="i">{{item}}</p>
                        </div>
                    </div>
                    <div class="cases">
                        <div v-for="(item,i) of robot.cases" :key="i">
                            <div class="subtitle">{{item.title}}</div>
                            <div class="subContet">
                               <div :style="{ 'backgroundImage': `url(${item.url})` ,'background-repeat':'no-repeat','background-position':'center','background-size':'cover','height':'2.5rem','border':'0.01rem dashed #808080'}"></div>
                               <div class="content" v-if="item.content!==''">{{item.content}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="productChildren" v-if="isActive==2">
                    <div class="subtitle">{{screw.introduction}}</div>
                    <div class="device">
                        <div class="left" :style="{ 'backgroundImage': `url(${screw.device.url})` ,'background-repeat':'no-repeat','background-position':'center','background-size':'cover','height':'4rem'}"></div>
                        <div class="right">
                            <div class="subtitle">{{screw.device.title}}</div>
                            <p  class="content" v-for="(item,i) of screw.device.contents" :key="i">{{item}}</p>
                        </div>
                    </div>
                    <div class="cases">
                        <div v-for="(item,i) of screw.cases" :key="i">
                            <div class="subtitle">{{item.title}}</div>
                            <div class="subContet">
                               <div :style="{ 'backgroundImage': `url(${item.url})` ,'background-repeat':'no-repeat','background-position':'center','background-size':'cover','height':'2.5rem','border':'0.01rem dashed #808080'}"></div>
                               <div class="content" v-if="item.content!==''">{{item.content}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="productChildren" v-if="isActive==3">
                    <div class="subtitle">{{optics.introduction}}</div>
                    <div class="device">
                        <div class="left" :style="{ 'backgroundImage': `url(${optics.device.url})` ,'background-repeat':'no-repeat','background-position':'center','background-size':'cover','height':'4rem'}"></div>
                        <div class="right">
                            <div class="subtitle">{{optics.device.title}}</div>
                            <p class="content" v-for="(item,i) of optics.device.contents" :key="i">{{item}}</p>
                        </div>
                    </div>
                    <div class="cases">
                        <div v-for="(item,i) of optics.cases" :key="i" style="width:45%">
                            <div class="subtitle">{{item.title}}</div>
                            <div class="subContet" >
                               <div :style="{ 'backgroundImage': `url(${item.url})` ,'background-repeat':'no-repeat','background-position':'center','background-size':'cover','height':'3rem','border':'0.01rem dashed #808080'}"></div>
                               <div class="content" v-if="item.content!==''">{{item.content}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import productData from '@/assets/js/eduProduct.js'
export default {
    name: 'eduCourse',
    data () {
        return {
            isActive: 1,
            robot:productData.robot,
            screw:productData.screw,
            optics:productData.optics,
        }
    },
    mounted() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
    methods: {
       
    }

}
</script>
<style lang="scss" scoped>
.edu-aids {
    .banner{
        p:nth-child(3){
            margin-right: 28%;
        }
    }
    .paragraphs{
        .subtitle{
                font-size: 0.2rem;
                // font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;
                line-height: 0.4rem;
                margin-bottom: 0.1rem;
                text-indent: 2em;
           }
               .content{
                font-size: 0.16rem;
                // font-family: Microsoft YaHei;
                font-weight: 400;
                color: #808080;
                line-height: 0.3rem;
                text-indent: 2em;
             }
        .productTab {
            .tabs {
                margin-bottom: 0.4rem;
                display: flex;
                justify-content: space-between;
                span {
                    display: inline-block;
                    width: 30%;
                    height: 0.64rem;
                    border-radius: 0.03rem;
                    background: #F7F7F7;
                    text-align: center;
                    cursor: pointer;
                    color: #0061E3;
                    line-height: 0.6rem;
                    font-size: 0.24rem;
                }
                .active {
                    background: #0061E3;
                    color: #FFFFFF;
                }
            }
        }
        .introduction{
            font-size: 0.18rem;
            // font-family: Microsoft YaHei;
            font-weight: 400;
            color: #808080;
            line-height: 0.35rem;
            text-indent: 2em;
        }
        .device{
            margin-top: 0.5rem;
            display: flex !important;;
            justify-content: space-between;
            align-items: center;
            >div{
                width: 50%;
            }
            .right{
            box-sizing: border-box;
            padding-left: 0.2rem;
            }
        }
        .cases{
            margin-top: 0.5rem;
            display: flex;
            justify-content: space-between;
            .subtitle{
                text-align: center;
                text-indent: 0;
            }
            >div{
                width: 30%;
            }
            .subContet{
                background: #FFFFFF;
                box-shadow: 0px 6px 20px 0px rgba(163, 163, 163, 0.27);
                .content{
                    height:1.3rem;
                    margin-top: 0.1rem; 
                    display: flex;
                    align-items: center;
                    padding: 0 0.1rem 0;
                    // box-sizing: border-box;
                }
               
            }
        }
    }
}

</style>
