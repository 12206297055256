/* eslint-disable */
export default {
    robot:{
       title:'智能象棋对弈机器人',
        introduction:'智能象棋对弈机器人通过棋盘识别算法、下棋策略算法以及机械控制算法三大部分实现人机对弈。整体设计涉及机器视觉、图像处理、深度学习、统计学、优化算法、搜索算法、电子技术、负反馈控制系统、PID控制、PWM、滤波算法等领域的知识。',
        device:{
            title:'设备组成',
            contents:['机械臂装置、机械臂控制机、视频采集、监视器、边缘智能实验箱、边缘端智能卡。','智能象棋对弈机器人是南京智算中心面向高校人工智能专业自主研发的一款实验实训教具，将多项人工智能算法和机械控制相结合，引导学生深入人工智能方向的学习，全面提升学生在人工智能、电子、机械方面的综合能力。'],
            url:require("@/assets/images/edu_products/robot_p1_1.png")
        },
        cases:[
            {title:'边缘智能实验箱',content:'Extrobox202是南京智算中心开发的一款边缘端AI智能盒子产品。具有8Tops的强大算力，可直接在前端运行各类深度学习算法，并进行图像的实时处理等。',url:require("@/assets/images/edu_products/robot_p2_1.png")},
            {title:'下棋策略算法',content:'下棋策略算法根据历史棋局分析人类棋手的下棋策略和喜好，结合当前棋局状态进行深度分析，依据局面的优势变化得出最优走子方式。',url:require("@/assets/images/edu_products/robot_p2_2.png")},
            {title:'机械控制算法',content:'机械控制算法根据下棋策略算法的走子方式进行机械臂的控制，多种传感器协调工作对机械臂进行定位、纠正，确保机械臂位置准确、运动平稳无抖动。',url:require("@/assets/images/edu_products/robot_p2_3.png")},
        ]
    },
    screw:{
        title:'全自动锁螺丝设备',
         introduction:'全自动锁螺丝设备是南京智算中心将实际生产中的工业产品简化而成的实验实训教具，用于学校人工智能教学，有利于学生结合实际生产场景，对人工智能有更深入的理解。该教具主要考察机器视觉、图像处理、深度学习方面的知识点。',
         device:{
             title:'设备组成',
             contents:['吹气式自动螺丝机、物料供给机、高分辨率摄像头、边缘智能实验箱、边缘端智能卡。','全自动锁螺丝设备应用于各类螺丝工件，基于机器视觉的图像语义分割和目标检测，自动识别螺丝和孔位，联动螺丝机自动化设备实现全自动作业和缺陷监测。'],
             url:require("@/assets/images/edu_products/screw_p1_1.png")
         },
         cases:[
             {title:'',content:'',url:require("@/assets/images/edu_products/screw_p2_1.png")},
             {title:'',content:'',url:require("@/assets/images/edu_products/screw_p2_2.png")},
             {title:'',content:'',url:require("@/assets/images/edu_products/screw_p2_3.png")},
         ]
     },
     optics:{
        title:'CCD光学检测设备',
         introduction:'CCD光学瑕疵检测设备应用于螺母器件的外观瑕疵检测，利用CCD工业相机和工业标准光源配合抓取检测台的螺母图像，图像输入到边缘智能实验箱，机器视觉算法通过搭载的边缘端智能盒快速、精准地识别产品表面瑕疵。并将瑕疵标注出来在监视器上进行显示。',
         device:{
             title:'设备组成',
             contents:['CCD 工业相机、检测台、光源装置、监视器、边缘智能实验箱、边缘端智能卡。','CCD光学瑕疵检测设备是南京智算中心面向高校提供的人工智能实训教具，帮助学生深入学习机器视觉、图像处理、深度学习方面的知识点，满足视觉感知和表面瑕疵智能检测需要，提升学生在人工智能方面的能力。'],
             url:require("@/assets/images/edu_products/optics_p1_1.png")
         },
         cases:[
             {title:'视觉感知模块',content:'CCD光学瑕疵检测设备的视觉感知模块功能是通过固定光源，保证摄像头拍摄效果，通过摄像头实时获取拍摄到的螺母图像，返回螺母图像的多维数组数据。',url:require("@/assets/images/edu_products/optics_p2_1.png")},
             {title:'表面瑕疵智能检测模块',content:'CCD光学瑕疵检测设备表面瑕疵智能检测模块用于处理感知模块采集到的图像数据并返回处理过后的数据，满足视觉感知和表面瑕疵智能检测需要。',url:require("@/assets/images/edu_products/optics_p2_2.png")},
         ]
     },
     
}